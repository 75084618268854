typeof window !== "undefined" && require("boxicons");
import React from "react";
import Link from "gatsby-link";
import PropTypes from "prop-types";

export default function AboveFooter({ data }) {
  return (
    <div
      className="aboveFooter"
      style={{ fontFamily: "Source Sans Pro", textAlign: "left" }}
    >
      <div className="lg:text-left grid grid-cols-1 md:grid-cols-4 gap-8 m-10 md:mx-20 md:my-10">
        <div>
          <b style={{ fontFamily: "Source Sans Pro" }} className="text-lg">
            A Bivver
          </b>
          <br />
          <br />
          <Link to="/sobre#quemSomos" className="barraFinal">
            Sobre a Bivver
          </Link>
          <br />
          <br />
          <Link to="/sobre#missao" className="barraFinal">
            Missão
          </Link>
          <br />
          <br />
          <Link to="/sobre#visao" className="barraFinal">
            Visão
          </Link>
          <br />
          <br />
          <Link to="/sobre#valores" className="barraFinal">
            Valores
          </Link>
          <br />
          <br />
          <Link to="/sobre#compromissoSocial" className="barraFinal">
            Compromisso social
          </Link>
        </div>
        <div>
          <b style={{ fontFamily: "Source Sans Pro" }} className="text-lg">
            Linha de produtos
          </b>
          <br />
          <br />
          <Link to="/nossasLinhas/bivverDream" className="barraFinal">
            Bivver Dream
          </Link>
          <br />
          <br />
          <Link to="/nossasLinhas/bivverClub" className="barraFinal">
            Bivver Club
          </Link>
          <br />
          <br />
          <Link to="/nossasLinhas/bivverExclusivity" className="barraFinal">
            Bivver Exclusivity
          </Link>
        </div>
        {/* {data?.lancamento?.length > 0 && (
          <div>
            <b style={{fontFamily:'Oxygen'}} className="text-lg">Lançamentos</b>
            <br />
            <br />
            {data?.lancamento?.map((e) => (
              <>
                 <Link
                  key={e.id}
                  to={`/predio?id=${e.id}`}
                  className="barraFinal"
                >
                  {e.nomePredio}
                </Link>
                <br />
                <br />
              </>
            ))}
          </div>
        )} */}
        {data?.em_andamento?.length > 0 && (
          <div>
            <b style={{ fontFamily: "Source Sans Pro" }} className="text-lg">
              Obras em andamento
            </b>
            <br />
            <br />
            {data?.em_andamento?.map((e) => (
              <>
                <Link
                  key={e.id}
                  to={`/predio?id=${e.id}`}
                  className="barraFinal"
                >
                  {e.nomePredio}
                </Link>
                <br />
                <br />
              </>
            ))}
          </div>
        )}
        {data?.pronto?.length > 0 && (
          <div>
            <b style={{ fontFamily: "Source Sans Pro" }} className="text-lg">
              Prontos para morar
            </b>
            <br />
            <br />
            {data?.pronto?.map((p) => (
              <>
                <Link
                  key={p.id}
                  to={`/predio?id=${p.id}`}
                  className="barraFinal"
                >
                  {p.nomePredio}
                </Link>
                <br />
                <br />
              </>
            ))}
          </div>
        )}
        <div>
          <b style={{ fontFamily: "Source Sans Pro" }} className="text-lg">
            Redes sociais:
          </b>
          <div className="flex mt-2">
            <a
              className="barraFinal"
              href="https://www.facebook.com/Bivver-Empreendimentos-109070777371707"
              rel="noopener noreferrer"
              target="_blank"
            >
              <i className="bx bxl-facebook -ml-3 text-4xl"></i>
            </a>
            <a
              className="ml-1 barraFinal"
              href="https://www.instagram.com/bivver_empreendimentos"
              rel="noopener noreferrer"
              target="_blank"
            >
              <i className="bx bxl-instagram text-4xl"></i>
            </a>
            <a
              className="ml-1 barraFinal"
              href="https://www.linkedin.com/company/bivver-empreendimentos/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <i className="bx bxl-linkedin text-4xl"></i>
            </a>
          </div>
        </div>
      </div>
      <div className="mx-3">
        <hr />
      </div>
    </div>
  );
}
AboveFooter.propTypes = { data: PropTypes.any };
