import React from "react";
import PropTypes from "prop-types";

export default function PageTitle({ imageUrl, title, text }) {
  return (
    <React.Fragment>
      <div
        className="text-center md:mT-15 py-10"
        style={{
          background: "#FAFAFA 0% 0% no-repeat padding-box",
        }}
      >
        <center>
          {imageUrl && (
            <img className="lg:w-1/6 w-1/2 m-5 self-center" src={imageUrl} />
          )}
          <h1
            className={`mt-${imageUrl || text ? "3" : "12"} lg:mx-32 mb-${
              text ? "3" : "12"
            }`}
          >
            {title}
          </h1>
          {text && (
            <p
              style={{
                textAlign: "center;",
                font: "normal normal normal 24px/36px Source Sans Pro;",
                letterSpacing: "0px;",
                color: "#000000;",
                opacity: 0.54,
              }}
              className="lg:w-3/5 my-4"
            >
              {text}
            </p>
          )}
        </center>
      </div>
    </React.Fragment>
  );
}

PageTitle.propTypes = {
  imageUrl: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
};
