/* eslint-disable react/jsx-key */
typeof window !== "undefined" && require("boxicons");
import Link from "gatsby-link";
import PropTypes from "prop-types";
import React from "react";

const Navigation = ({ isExpanded, data }) => {
  return (
    <nav
      className={`nav md:inline-flex ${isExpanded ? `block` : `hidden`}`}
    >
      <ul className="md:flex">
        <li>
          <Link to="/">Início</Link>
        </li>
        <li>
          <Link to="/sobre">Sobre a Bivver</Link>
        </li>
        <li className="icon-margin">
          <Link
            style={{ position: "relative", bottom: "0.41rem" }}
            to="/nossasLinhas"
          >
            Nossas Linhas{" "}
            <box-icon
              style={{ position: "relative", top: "0.38rem" }}
              size="sm"
              name="chevron-down"
            />
          </Link>
          <ul className="row" style={{ maxWidth: 600 }}>
            <li className="col-md-6 d-flex flex-column">
              <Link to="/nossasLinhas/bivverDream">Bivver Dream</Link>
            </li>
            <li className="col-md-6 d-flex flex-column">
              <Link to="/nossasLinhas/bivverClub">Bivver Club</Link>
            </li>
            <li className="col-md-6 d-flex flex-column">
              <Link to="/nossasLinhas/bivverExclusivity">
                Bivver Exclusivity
              </Link>
            </li>
          </ul>
        </li>
        {data?.lancamento?.length > 0 && (
          <li className="icon-margin">
            <Link
              style={{ position: "relative", bottom: "0.41rem" }}
              to="/lancamentos"
            >
              Lançamentos{" "}
              <box-icon
                style={{ position: "relative", top: "0.38rem" }}
                size="sm"
                name="chevron-down"
              />
            </Link>
            <ul className="row" style={{ maxWidth: 600 }}>
              {data?.lancamento.map((e) => (
                <li className="col-md-6 d-flex flex-column">
                  <Link to={`/predio?id=${e.id}`}>{e.nomePredio}</Link>
                </li>
              ))}
            </ul>
          </li>
        )}
        {data?.em_andamento?.length > 0 && (
          <li className="icon-margin">
            <Link
              style={{ position: "relative", bottom: "0.41rem" }}
              to="/predios"
              className="b-2"
            >
              Obras em Andamento{" "}
              <box-icon
                style={{ position: "relative", top: "0.38rem" }}
                size="sm"
                name="chevron-down"
              />
            </Link>
            <ul className="pl-3">
              {data?.em_andamento.map((e) => (
                <li className="col-md-6 d-flex flex-column">
                  <Link to={`/predio?id=${e.id}`}>{e.nomePredio}</Link>
                </li>
              ))}
            </ul>
          </li>
        )}
        {data?.pronto?.length > 0 && (
          <li className="icon-margin">
            <Link
              style={{ position: "relative", bottom: "0.41rem" }}
              to="/predios"
              className="b-2"
            >
              Pronto para morar{" "}
              <box-icon
                style={{ position: "relative", top: "0.38rem" }}
                size="sm"
                name="chevron-down"
              />
            </Link>
            <ul className="pl-3">
              {data?.pronto.map((e) => (
                <li className="col-md-6 d-flex flex-column">
                  <Link to={`/predio?id=${e.id}`}>{e.nomePredio}</Link>
                </li>
              ))}
            </ul>
          </li>
        )}
        <li>
          <Link to="http://bivver.com.br/blog">Blog</Link>
        </li>
        <li>
          <Link to="/contato">Contato</Link>
        </li>
      </ul>
    </nav>
  );
};
Navigation.propTypes = {
  isExpanded: PropTypes.bool,
  setLoadingPage: PropTypes.func,
  data: PropTypes.array,
};

export default Navigation;
