import React, { useContext } from "react";
import Logo from "../images/bivver-logo-branco.png";
import { AuthContext } from "../context/GlobalContextProvider";

function Footer() {
  const context = useContext(AuthContext);
  return (
    <footer
      style={{
        float: "bottom",
        backgroundColor: "#212121",
      }}
      className='text-left'
    >
      <div
        style={{ fontSize: "1rem" }}
        className="bivver grid grid-cols-1 md:grid-cols-4 gap-12 m-10 md:mx-20 md:my-16"
      >
        <div className="col-span-1">
          <a href="#topo" className="flex ">
            <img src={Logo} className="ml-8 mt-5 w-1/2" />
          </a>
        </div>
        <div className="col-span-1 lg:col-start-2" style={{fontSize:'15px'}}>
          <div className="my-3">Bivver Empreendimentos Imobiliários</div>
          <div className="my-3">Endereço: Rua Iririú, 847, Joinville, SC</div>
          <div className="my-3">CEP: 89221-515</div>
        </div>
        <div className="col-span-1 lg:col-start-3" style={{fontSize:'15px'}}>
          <div className="my-3">Contato:</div>
          <div className="my-3">WhatsApp: (47) 99787-3689</div>
          <div className="my-3">Email: comercial@bivver.com.br</div>
        </div>
        <div className="col-span-1 lg:col-start-4" style={{fontSize:'15px'}}>
          {context?.user && (
            <>
              <div className="mt-4">Logado como: {context?.user?.username}</div>
              <div
                className="mt-4 cursor-pointer"
                onClick={context?.handleLogout}
              >
                Fazer logout?
              </div>
            </>
          )}
        </div>
        <section style={{ fontSize: "0.85rem" }} className="p-2 text-white">
          <div>
            © 2020 | Desenvolvido por{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/in/ricardo-reinert-572a96145/"
            >
              Ricardo Reinert Karnopp
            </a>
          </div>
        </section>
      </div>
    </footer>
  );
}

export default Footer;
