/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import Header from "./header";
import AboveFooter from "./aboveFooter";
import FixedBar from "./fixedBar";
import api, { url } from "../api";
import { LoadingOutlined } from "@ant-design/icons";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { navigate } from "gatsby";
import { Skeleton } from "antd";
import SubscriptionBanner from "./subscriptionBanner";
import PageTitle from "./pageTitle";
import Logo from "../images/Bivver.png";

function Layout({
  image,
  carousel,
  children,
  loading,
  dontShowBanner,
  titlePage,
  pageDesctiption,
}) {
  const [loadingNavbar, setLoadingNavbar] = useState(false);

  const [data, setData] = useState();

  useEffect(() => {
    setLoadingNavbar(true);
    getData();
  }, []);
  const getData = async () => {
    var response = await api.get("/navbar");

    const navbar = localStorage.getItem("navbar");

    if (response.data != JSON.parse(navbar)) {
      setData(response.data);
      localStorage.setItem("navbar", JSON.stringify(response.data));
    }

    setLoadingNavbar(false);
  };

  const arrowStyles = {
    position: "absolute",
    zIndex: 2,
    top: "calc(50% - 15px)",
    width: 30,
    height: 30,
    cursor: "pointer",
    color: "white",
    backgroundColor: "#D27A34",
  };

  if (loadingNavbar) {
    return (
      <div className="text-center home-loading">
        <img src={Logo} className="h-10 img-loading" />
        <LoadingOutlined className="-ml-32" style={{ fontSize: "15rem" }} />
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen align-middle text-gray-900">
      <Header {...{ data, loading }} />
      <Skeleton className="m-20" active loading={loading}>
        {carousel && (
          <Carousel
            autoPlay
            infiniteLoop
            dynamicHeight={true}
            interval={3000}
            transitionTime={500}
            renderIndicator={(clickHandler, isSelected) => (
              <div
                onClick={clickHandler}
                style={{
                  height: "0.5rem",
                  width: "0.5rem",
                  borderRadius: "8px",
                  display: "inline-block",
                  margin: "0 8px",
                  backgroundColor: isSelected ? "#D27A34" : "#E0E0E0",
                }}
              ></div>
            )}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <div
                  className="rounded-full btn-bivver"
                  onClick={onClickHandler}
                  title={label}
                  style={{
                    ...arrowStyles,
                    alignContent: "center",
                    alignItems: "center",
                    left: 15,
                  }}
                >
                  <div
                    style={{ position: "relative", left: "40%", top: "15%" }}
                  >
                    {"<"}
                  </div>
                </div>
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <div
                  className="rounded-full btn-bivver"
                  onClick={onClickHandler}
                  title={label}
                  style={{
                    ...arrowStyles,
                    alignContent: "center",
                    alignItems: "center",
                    right: 15,
                  }}
                >
                  <div
                    style={{ position: "relative", left: "40%", top: "15%" }}
                  >
                    {">"}
                  </div>
                </div>
              )
            }
            showThumbs={false}
            showStatus={false}
            stopOnHover={false}
            className={`index-carousel ${carousel ? "link" : ""}`}
          >
            {carousel.map(
              (c) =>
                c.capa && (
                  <div
                    key="s"
                    onClick={() => navigate(`/predio?id=${c.id}`)}
                    className="md:text-justify caroussel-image mr-auto"
                    style={{
                      backgroundImage: `url(${url}${c.capa?.url})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></div>
                )
            )}
          </Carousel>
        )}
        {titlePage && (
          <PageTitle
            title={titlePage}
            imageUrl={image}
            text={pageDesctiption}
          />
        )}
        <main
          style={{ background: "white" }}
          className="bivverBlack flex flex-col flex-1 md:justify-center mx-auto md:px-8 w-4/5 m-4 md:my-6"
        >
          {children}
        </main>
      </Skeleton>
      {!dontShowBanner && <SubscriptionBanner />}
      <div>
        <FixedBar />
      </div>
      <footer className="footer">
        <AboveFooter {...{ data }} />
        <Footer />
      </footer>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  image: PropTypes.any,
  carousel: PropTypes.array,
  loading: PropTypes.bool,
  dontShowBanner: PropTypes.bool,
  titlePage: PropTypes.string,
  pageDesctiption: PropTypes.string,
};

export default Layout;
