import Link from "gatsby-link";
import React, { useState } from "react";
import PropTypes from "prop-types";
import Logo from "../images/Bivver.png";
import Navigation from "./navigation";
import Menu from "../images/menu.png";
// import { LoadingOutlined } from "@ant-design/icons";

function Header({ image, data, loading }) {
  const [isExpanded, toggleExpansion] = useState(false);
  return (
    <header>
      <div id="topo" className="flex lg:justify-between">
        <div className="hidden lg:flex w-1/5 my-3">
          <Link to="/" className="ml-20 mt-5">
            <img src={Logo} />
          </Link>
        </div>
        <div>
          <Navigation {...{ isExpanded, data }} />
        </div>
      </div>

      <div className={`flex ${isExpanded && "-mt-40 h-40"}`}>
        <Link
          to="/"
          className={`block ${((isExpanded && image) ||
            (!isExpanded && image) ||
            (isExpanded && !image)) &&
            "hidden"} lg:hidden w-1/3 m-1`}
        >
          <img src={Logo} />
        </Link>
        <button
          className="block lg:hidden ml-auto px-3 py-2 text-white"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <img src={Menu} className="h-6 mr-3" />
        </button>
      </div>

      {image != undefined && !loading ? <center>{image}</center> : ""}
    </header>
  );
}

Header.propTypes = {
  image: PropTypes.any,
  loading: PropTypes.any,
  carousel: PropTypes.array,
  data: PropTypes.array,
};

export default Header;
