import { Button } from "antd";
import React, { useState } from "react";
import MailchimpModal from "./mailchimpModal";

export default function SubscriptionBanner() {
  const [visible, setVisible] = useState(false);

  return (
    <React.Fragment>
      <MailchimpModal {...{ visible, setVisible }} />

      <div
        className="lg:text-left text-center grid grid-cols-1 lg:grid-cols-3 lg:gap-12 gap-6 lg:mt-10 py-6"
        style={{
          background: "#FAFAFA 0% 0% no-repeat padding-box",
        }}
      >
        <div className="col-span-2">
          <div
            style={{
              fontSize: "1.8rem",
              fontFamily: "Oxygen",
              fontWeight: "bold",
            }}
            className="lg:ml-32 mx-3 lg:mr-32"
          >
            Cadastre-se e receba em primeira mão as novidades e lançamentos da
            Bivver Empreendimentos
          </div>
        </div>
        <div className="lg:col-span-1">
          <Button
            onClick={() => setVisible(true)}
            style={{
              borderRadius: "8px",
              opacity: 1,
              color: "white",
              font: "normal normal 600 20px/24px Segoe UI",
            }}
            className="lg:w-2/3 btn-bivver h-24"
          >
            RECEBER NOVIDADES
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}
